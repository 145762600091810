.SkillCardContent {
    height: 100%;
    padding: 0em 1em;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: disc;
    & li {
      list-style: disc;
      color: var(--secondary);
    }
    color: var(--secondary);
    @media screen and (max-width: 500px) {
      padding: 0em 1em;
    }
  }