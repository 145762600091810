.overlay {
  position: fixed;
  inset: 0px;
  background-color: var(--background);
  opacity: 0.8;
  z-index: 10;
  cursor: pointer;
}

.zoomMmodal {
  animation: modal 0.6s;
  display: flex;
  align-items: center;
  flex-direction: column;
  inset: 5% 14vw;
  position: fixed;
  z-index: 10;
  padding: 1em;
  background-color: var(--background);
  border: 1px solid var(--primary);
  height: fit-content;
  @media screen and (max-width: 768px) {
    inset: 13% 8vw;
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    inset: 13% 4vw;
    width: 85%;
  }
  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__close {
      & svg {
        width: 1.3em;
        height: 1.3em;
        transition: transform 0.1s;
        color: var(--primary);
        &:hover {
          transition: transform 0.1s;
          transform: scale(1.1);
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      border-radius: 5px;
      width: 2em;
      height: 2em;
      background-color: var(--background);
      &:hover {
        background-color: var(--accent);
      }
    }
    &__content {
      height: 100%;
      padding: 0.2em 0em;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--secondary);
    }
  }
}

@keyframes modal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
