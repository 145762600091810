.overlay {
  position: fixed;
  inset: 0px;
  background-color: var(--background);
  opacity: 0.8;
  z-index: 10;
  cursor: pointer;
}

.modal {
  animation: modal 0.6s;
  display: flex;
  align-items: center;
  flex-direction: column;
  inset: 13% 14vw;
  position: fixed;
  z-index: 10;
  padding: 1em;
  background-color: var(--background);
  border: 1px solid var(--primary);
  height: fit-content;
  @media screen and (max-width: 768px) {
    inset: 13% 8vw;
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    inset: 5% 4vw;
    width: 85%;
  }
  &__section {
    width: 95%;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid var(--primary);
      gap: 1em;
      & h2 {
        padding: 1rem 0em;
        color: var(--primary);
        margin-right: auto;
      }
      &__close {
        & svg {
          width: 1.3em;
          height: 1.3em;
          transition: transform 0.1s;
          color: var(--primary);
          &:hover {
            transition: transform 0.1s;
            transform: scale(1.1);
          }
        }
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: white;
        border-radius: 5px;
        width: 2em;
        height: 2em;
        background-color: var(--background);
        &:hover {
          background-color: var(--accent);
        }
      }
    }
    &__content {
      height: 100%;
      padding: 1em 0em;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--secondary);
      @media screen and (max-width: 500px) {
        padding: 2em 1em;
      }
    }
  }
}

@keyframes modal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
