.welcome {
  background-image: url("../../img/paysage.jpg");
  height: clamp(500px, 50vw, 1980px);
  max-height: 900px;
  background-size: cover;
  &__image {
    width: 100%;
    height: 100%;
    background-color: #2a150675;
    box-shadow: inset 0px -40px 20px 5px #2a1506;
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        flex-direction: row;
        gap: 1em;
        p {
          color: var(--secondary);
        }
      }
      h2 {
        font-size: clamp(70px, 10vw, 200px);
        line-height: 1em;
      }
      h3 {
        font-size: clamp(50px, 6vw, 500px);
        line-height: 1em;
      }
      h4 {
        font-size: clamp(40px, 4vw, 500px);
      }
      p {
        font-size: clamp(30px, 3vw, 500px);
        line-height: 1em;
      }
    }
  }
}
