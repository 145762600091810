.skill {
  padding: 1em 5em;
  @media screen and (max-width: 620px) {
    padding: 1em 1em;
  }
  &__edition {
    display: flex;
    flex-direction: column;
    border-top: var(--primary) 2px solid;
    border-bottom: var(--primary) 2px solid;
    justify-content: center;
    align-items: center;
    padding: 2em 0em 5em 0em;
    width: 100%;
    gap: 3em;
    
    &__content {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      gap: 2em;

      // animation au scroll (réglage)
      animation: reveal linear;
      animation-timeline: view();
      animation-range-start: cover 0%;
      animation-range-end: cover 30%;
      @media screen and (max-width: 1000px) {
        animation: none;
      }
    }
  }
}

// animation de reveal au scroll
@keyframes reveal {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}