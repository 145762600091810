.skillCard {
  cursor: pointer;

  &--border {
    transition: transform 0.2s;
    &:hover {
      transition: transform 0.2s;
      transform: scale(1.05);
    }
    position: relative;
    flex: 1 20%;
    padding: 3px;
    background-image: linear-gradient(
      to right bottom,
      #efc900,
      #c69214,
      #956119,
      #603715,
      #2a1506
    );
    .skillCard {
      min-width: 200px;
      min-height: 200px;
      padding: 1em;
      background-color: var(--background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
  }
}
