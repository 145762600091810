.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 5em;
  gap: 3em;
  @media screen and (max-width: 620px) {
    padding: 1em 1em;
  }
  &__border {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2em;
    padding: 4em 0em;
    border-top: var(--primary) 2px solid;
    border-bottom: var(--primary) 2px solid;
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1em;

    // animation au scroll (réglage)
    animation: reveal linear;
    animation-timeline: view();
    animation-range-start: cover 0%;
    animation-range-end: cover 30%;
    @media screen and (max-width: 1000px) {
      animation: none;
    }
    &--border {
      width: 70%;
      transition: transform 0.2s;
      position: relative;
      flex: 1 20%;
      padding: 3px;
      background-image: linear-gradient(
        to right bottom,
        #efc900,
        #c69214,
        #956119,
        #603715,
        #2a1506
      );
    }
    & label {
      color: var(--secondary);
    }
    & input,
    textarea {
      background-color: var(--background);
      width: calc(100% - 1em);
      height: 1em;
      color: var(--secondary);
      padding: 0.5em;
      outline: none;
    }
    & textarea {
      margin-bottom: -4px;
      height: 3em;
      resize: none;
    }
    & button[type="submit"] {
      background: none;
      border: none;
      & svg {
        color: var(--secondary);
      }

      cursor: pointer;
      &:hover {
        transition: transform 0.1s;
        transform: scale(1.3);
        color: var(--primary);
      }
    }
  }
}
