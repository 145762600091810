.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 3em 1em 3em;
  &__left {
    margin-right: auto;
  }
  &__ref {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
}
