.careerCard {
  background-image: linear-gradient(
    to left top,
    #efc900,
    #c69214,
    #956119,
    #603715,
    #2a1506
  );
  display: flex;
  justify-content: center;
  align-items: center;

  &__formation {
    width: 400px;
    @media screen and (max-width: 1600px) {
      width: 300px;
    }
    @media screen and (max-width: 1000px) {
      animation: none;
    }
    padding: 1em;
    margin: 3px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    h3 {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 0.5em;
    }

    p {
      padding: 0.5em;
      color: var(--secondary);
    }
  }
}
