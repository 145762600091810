.slider {
  height: 400px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--primary);
}

.projets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  gap: 3em;
  @media screen and (max-width: 620px) {
    padding: 1em 1em;
  }

  &__border {
    padding: 3px;
    background-image: linear-gradient(
      to right bottom,
      #efc900,
      #c69214,
      #956119,
      #603715,
      #2a1506
    );

    // animation au scroll (réglage)
    animation: reveal linear;
    animation-timeline: view();
    animation-range-start: cover 0%;
    animation-range-end: cover 30%;
    @media screen and (max-width: 1000px) {
      animation: none;
    }

    max-width: 80vw;
  }
}

// animation de reveal au scroll
@keyframes reveal {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.carousel {
  background-color: var(--background);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 1em 0em;
  @media screen and (max-width: 700px) {
    gap: 1em;
    padding: 0.2em 0.4em;
  }
  max-width: 100%;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    svg {
      cursor: pointer;
      transition: transform 0.1s;
      &:hover {
        transition: transform 0.1s;
        transform: scale(1.1);
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    & svg {
      cursor: pointer;
    }
    .arrowUp {
      transition: transform 0.1s;
      rotate: 90deg;
      &:hover {
        transition: transform 0.1s;
        transform: scale(1.1);
      }
    }
    .arrowDown {
      transition: transform 0.1s;
      rotate: 270deg;
      &:hover {
        transition: transform 0.1s;
        transform: scale(1.1);
      }
    }
  }
  &__dots {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    @media screen and (max-width: 700px) {
      display: none;
    }
    & .dot {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      border: var(--primary) 3px solid;
    }
    & .dot.active {
      background-color: var(--primary);
    }
  }
}
