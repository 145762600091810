.header {
  background-color: rgba(42, 21, 6, 0.75);
  box-shadow: inset 0px -2px 2px -1px #ffcc00;
  z-index: 10;
  position: fixed;
  width: 100%;
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__section {
    padding: 0.5rem 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 1000px) {
      padding: 0.5rem 1rem;
    }
    &__title {
      margin-right: auto;
    }
    // desktop navbar
    &__nav {
      @media screen and (max-width: 500px) {
        display: none;
      }
      &__list {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 2em;
        margin: 1px;
        @media screen and (max-width: 1000px) {
          gap: 0.8em;
        }
        &__element {
          cursor: pointer;
          &:hover {
            box-shadow: inset 0px -2px 1px -1px #ffcc00;
          }
        }
      }
    }
  }

  // mobile navbar
  &__mobileMenu {
    display: none;
    @media screen and (max-width: 500px) {
      display: block;
    }
  }
  &__mobile {
    display: none; // Cache le menu par défaut

    &.active {
      display: block; // Affiche le menu lorsque la classe 'active' est ajoutée
      animation: start 0.5s;
    }
    &__list {
      background-color: rgba(42, 21, 6, 0.75);
      box-shadow: inset 0px -2px 2px -1px #ffcc00;

      position: fixed;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1em;
      padding: 1em 0em;
      & li:hover {
        transition: transform 0.1s;
        transform: scale(1.1);
      }
    }
  }
}
@keyframes start {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
