.slider {
  max-width: 100%;
}

.slide {
  width: 800px;
  height: 400px;
  position: relative;
  margin: 0 auto;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    object-fit: cover;
  }
}

.card {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 0.8s linear;
  transform-style: preserve-3d;
}
.card:hover {
  transform: rotateX(180deg);
}

.slider:hover {
  .card {
    transform: rotateX(180deg);
  }
}

.side {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  &--front {
    z-index: 1;
  }
  &--back {
    background-color: var(--background);
    transform: rotateX(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    &__content {
      padding: 0em 0em 0em 4em;
      width: 80%;
      &__title {
      }
      &__langage {
        color: var(--secondary);
        padding-bottom: 0.5em;
      }
      &__text {
        color: var(--secondary);
      }
    }
  }
}
