// // reset.scss

// // Box model
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, textarea, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'Tangerine', cursive;
  vertical-align: baseline;
}

html {
  background-color: var(--background);
  scroll-behavior: smooth;
  animation-timeline: auto;
}

// // HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

// // Body
body {
  margin: 0;
  padding: 0;
}

// Lists
ol, ul {
  list-style: none;
}

// Links
a {
  text-decoration: none;
}

// themes :

:root {
  --primary: #EFC900;
  --secondary: #EFDCCC;
  --tertiary: #542A0C;
  --background: #2A1506;
  --background-contrast: rgba(42, 21, 6, 0.65);
  
  --linear1: linear-gradient(315deg, rgba(255, 215, 0, 1) 0%, rgba(255, 215, 0, 1) 100%);
  --linear2: linear-gradient(135deg, rgba(255, 215, 0, 1) 0%, rgba(255, 215, 0, 1) 100%);
  
  --shadow-inner: inset 7px 7px 22px -8px #000000;
  --shadow-outer: -2px -2px 10px 1px #000000;
}

// preset et font-size :

html {
  // responsive text
  --textRes: calc(0.01 * 10vmin);
}

h1,h2,h3,h4,p,ul,li, button {
  color: var(--primary);
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 45px;
}

h1, p, li, input, label, textarea, button {
  font-size: clamp(30px, 3vw, 45px)
}

#root svg {
  font-size: 45px;
  color: var(--primary);
}

button {
  text-align: center;
  align-items: center;
  display: flex;
  gap: 10px;
}

