.parcours {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 5em;
  gap: 3em;
  @media screen and (max-width: 620px) {
    padding: 1em 1em;
  }
  &__border {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2em;
    padding: 4em 0em;
    border-top: var(--primary) 2px solid;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 4em;

    // animation au scroll (réglage)
    animation: reveal linear;
    animation-timeline: view();
    animation-range-start: cover 0%;
    animation-range-end: cover 30%;

    @media screen and (max-width: 1000px) {
      animation: none;
    }
    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }
  }
  &__arrow {
    transform: rotate(-90deg);
  }
}

// animation de reveal au scroll
@keyframes reveal {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
