.projectGallery {
  width: 100%;
  height: clamp(150px, 55vh, 800px);
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1440px) {
    overflow: scroll;
    height: clamp(150px, 50vh, 800px);
  }
  &__image {
    border: 1px solid var(--primary);
    width: 250px;
    height: 250px;
    object-fit: cover;
    transition: all 0.2s linear;
    cursor: zoom-in;
  }
}
